/*
Copyright 2022 Adobe. All rights reserved.
This file is licensed to you under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License. You may obtain a copy
of the License at http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software distributed under
the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
OF ANY KIND, either express or implied. See the License for the specific language
governing permissions and limitations under the License.
*/

:host,
:root {
    --spectrum-overlay-opacity: 0.4;
    --spectrum-gray-background-color-default: var(--spectrum-gray-700);
    --spectrum-red-background-color-default: var(--spectrum-red-600);
    --spectrum-orange-background-color-default: var(--spectrum-orange-600);
    --spectrum-yellow-background-color-default: var(--spectrum-yellow-400);
    --spectrum-chartreuse-background-color-default: var(
        --spectrum-chartreuse-500
    );
    --spectrum-celery-background-color-default: var(--spectrum-celery-600);
    --spectrum-green-background-color-default: var(--spectrum-green-900);
    --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-900);
    --spectrum-cyan-background-color-default: var(--spectrum-cyan-900);
    --spectrum-blue-background-color-default: var(--spectrum-blue-900);
    --spectrum-indigo-background-color-default: var(--spectrum-indigo-900);
    --spectrum-purple-background-color-default: var(--spectrum-purple-900);
    --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-900);
    --spectrum-magenta-background-color-default: var(--spectrum-magenta-900);
    --spectrum-neutral-background-color-default: var(--spectrum-gray-800);
    --spectrum-neutral-background-color-hover: var(--spectrum-gray-900);
    --spectrum-neutral-background-color-down: var(--spectrum-gray-900);
    --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-900);
    --spectrum-neutral-subdued-background-color-default: var(
        --spectrum-gray-600
    );
    --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-700);
    --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-800);
    --spectrum-neutral-subdued-background-color-key-focus: var(
        --spectrum-gray-700
    );
    --spectrum-accent-background-color-default: var(
        --spectrum-accent-color-900
    );
    --spectrum-accent-background-color-hover: var(--spectrum-accent-color-1000);
    --spectrum-accent-background-color-down: var(--spectrum-accent-color-1100);
    --spectrum-accent-background-color-key-focus: var(
        --spectrum-accent-color-1000
    );
    --spectrum-informative-background-color-default: var(
        --spectrum-informative-color-900
    );
    --spectrum-informative-background-color-hover: var(
        --spectrum-informative-color-1000
    );
    --spectrum-informative-background-color-down: var(
        --spectrum-informative-color-1100
    );
    --spectrum-informative-background-color-key-focus: var(
        --spectrum-informative-color-1000
    );
    --spectrum-negative-background-color-default: var(
        --spectrum-negative-color-900
    );
    --spectrum-negative-background-color-hover: var(
        --spectrum-negative-color-1000
    );
    --spectrum-negative-background-color-down: var(
        --spectrum-negative-color-1100
    );
    --spectrum-negative-background-color-key-focus: var(
        --spectrum-negative-color-1000
    );
    --spectrum-positive-background-color-default: var(
        --spectrum-positive-color-900
    );
    --spectrum-positive-background-color-hover: var(
        --spectrum-positive-color-1000
    );
    --spectrum-positive-background-color-down: var(
        --spectrum-positive-color-1100
    );
    --spectrum-positive-background-color-key-focus: var(
        --spectrum-positive-color-1000
    );
    --spectrum-neutral-visual-color: var(--spectrum-gray-500);
    --spectrum-accent-visual-color: var(--spectrum-accent-color-800);
    --spectrum-informative-visual-color: var(--spectrum-informative-color-800);
    --spectrum-negative-visual-color: var(--spectrum-negative-color-800);
    --spectrum-notice-visual-color: var(--spectrum-notice-color-600);
    --spectrum-positive-visual-color: var(--spectrum-positive-color-700);
    --spectrum-gray-visual-color: var(--spectrum-gray-500);
    --spectrum-red-visual-color: var(--spectrum-red-800);
    --spectrum-orange-visual-color: var(--spectrum-orange-600);
    --spectrum-yellow-visual-color: var(--spectrum-yellow-600);
    --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-600);
    --spectrum-celery-visual-color: var(--spectrum-celery-700);
    --spectrum-green-visual-color: var(--spectrum-green-700);
    --spectrum-seafoam-visual-color: var(--spectrum-seafoam-700);
    --spectrum-cyan-visual-color: var(--spectrum-cyan-600);
    --spectrum-blue-visual-color: var(--spectrum-blue-800);
    --spectrum-indigo-visual-color: var(--spectrum-indigo-800);
    --spectrum-purple-visual-color: var(--spectrum-purple-800);
    --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-800);
    --spectrum-magenta-visual-color: var(--spectrum-magenta-800);
    --spectrum-drop-shadow-color: rgba(0, 0, 0, 0.15);
    --spectrum-background-base-color: var(--spectrum-gray-200);
    --spectrum-background-layer-1-color: var(--spectrum-gray-100);
    --spectrum-background-layer-2-color: var(--spectrum-gray-50);
    --spectrum-gray-50: rgb(255, 255, 255);
    --spectrum-gray-75: rgb(253, 253, 253);
    --spectrum-gray-100: rgb(248, 248, 248);
    --spectrum-gray-200: rgb(230, 230, 230);
    --spectrum-gray-300: rgb(213, 213, 213);
    --spectrum-gray-400: rgb(177, 177, 177);
    --spectrum-gray-500: rgb(144, 144, 144);
    --spectrum-gray-600: rgb(109, 109, 109);
    --spectrum-gray-700: rgb(70, 70, 70);
    --spectrum-gray-800: rgb(34, 34, 34);
    --spectrum-gray-900: rgb(0, 0, 0);
    --spectrum-red-100: rgb(255, 235, 231);
    --spectrum-red-200: rgb(255, 221, 214);
    --spectrum-red-300: rgb(255, 205, 195);
    --spectrum-red-400: rgb(255, 183, 169);
    --spectrum-red-500: rgb(255, 155, 136);
    --spectrum-red-600: rgb(255, 124, 101);
    --spectrum-red-700: rgb(247, 92, 70);
    --spectrum-red-800: rgb(234, 56, 41);
    --spectrum-red-900: rgb(211, 21, 16);
    --spectrum-red-1000: rgb(180, 0, 0);
    --spectrum-red-1100: rgb(147, 0, 0);
    --spectrum-red-1200: rgb(116, 0, 0);
    --spectrum-red-1300: rgb(89, 0, 0);
    --spectrum-red-1400: rgb(67, 0, 0);
    --spectrum-orange-100: rgb(255, 236, 204);
    --spectrum-orange-200: rgb(255, 223, 173);
    --spectrum-orange-300: rgb(253, 210, 145);
    --spectrum-orange-400: rgb(255, 187, 99);
    --spectrum-orange-500: rgb(255, 160, 55);
    --spectrum-orange-600: rgb(246, 133, 17);
    --spectrum-orange-700: rgb(228, 111, 0);
    --spectrum-orange-800: rgb(203, 93, 0);
    --spectrum-orange-900: rgb(177, 76, 0);
    --spectrum-orange-1000: rgb(149, 61, 0);
    --spectrum-orange-1100: rgb(122, 47, 0);
    --spectrum-orange-1200: rgb(97, 35, 0);
    --spectrum-orange-1300: rgb(73, 25, 1);
    --spectrum-orange-1400: rgb(53, 18, 1);
    --spectrum-yellow-100: rgb(251, 241, 152);
    --spectrum-yellow-200: rgb(248, 231, 80);
    --spectrum-yellow-300: rgb(248, 217, 4);
    --spectrum-yellow-400: rgb(232, 198, 0);
    --spectrum-yellow-500: rgb(215, 179, 0);
    --spectrum-yellow-600: rgb(196, 159, 0);
    --spectrum-yellow-700: rgb(176, 140, 0);
    --spectrum-yellow-800: rgb(155, 120, 0);
    --spectrum-yellow-900: rgb(133, 102, 0);
    --spectrum-yellow-1000: rgb(112, 83, 0);
    --spectrum-yellow-1100: rgb(91, 67, 0);
    --spectrum-yellow-1200: rgb(72, 51, 0);
    --spectrum-yellow-1300: rgb(54, 37, 0);
    --spectrum-yellow-1400: rgb(40, 26, 0);
    --spectrum-chartreuse-100: rgb(219, 252, 110);
    --spectrum-chartreuse-200: rgb(203, 244, 67);
    --spectrum-chartreuse-300: rgb(188, 233, 42);
    --spectrum-chartreuse-400: rgb(170, 216, 22);
    --spectrum-chartreuse-500: rgb(152, 197, 10);
    --spectrum-chartreuse-600: rgb(135, 177, 3);
    --spectrum-chartreuse-700: rgb(118, 156, 0);
    --spectrum-chartreuse-800: rgb(103, 136, 0);
    --spectrum-chartreuse-900: rgb(87, 116, 0);
    --spectrum-chartreuse-1000: rgb(72, 96, 0);
    --spectrum-chartreuse-1100: rgb(58, 77, 0);
    --spectrum-chartreuse-1200: rgb(44, 59, 0);
    --spectrum-chartreuse-1300: rgb(33, 44, 0);
    --spectrum-chartreuse-1400: rgb(24, 31, 0);
    --spectrum-celery-100: rgb(205, 252, 191);
    --spectrum-celery-200: rgb(174, 246, 157);
    --spectrum-celery-300: rgb(150, 238, 133);
    --spectrum-celery-400: rgb(114, 224, 106);
    --spectrum-celery-500: rgb(78, 207, 80);
    --spectrum-celery-600: rgb(39, 187, 54);
    --spectrum-celery-700: rgb(7, 167, 33);
    --spectrum-celery-800: rgb(0, 145, 18);
    --spectrum-celery-900: rgb(0, 124, 15);
    --spectrum-celery-1000: rgb(0, 103, 15);
    --spectrum-celery-1100: rgb(0, 83, 13);
    --spectrum-celery-1200: rgb(0, 64, 10);
    --spectrum-celery-1300: rgb(0, 48, 7);
    --spectrum-celery-1400: rgb(0, 34, 5);
    --spectrum-green-100: rgb(206, 248, 224);
    --spectrum-green-200: rgb(173, 244, 206);
    --spectrum-green-300: rgb(137, 236, 188);
    --spectrum-green-400: rgb(103, 222, 168);
    --spectrum-green-500: rgb(73, 204, 147);
    --spectrum-green-600: rgb(47, 184, 128);
    --spectrum-green-700: rgb(21, 164, 110);
    --spectrum-green-800: rgb(0, 143, 93);
    --spectrum-green-900: rgb(0, 122, 77);
    --spectrum-green-1000: rgb(0, 101, 62);
    --spectrum-green-1100: rgb(0, 81, 50);
    --spectrum-green-1200: rgb(5, 63, 39);
    --spectrum-green-1300: rgb(10, 46, 29);
    --spectrum-green-1400: rgb(10, 32, 21);
    --spectrum-seafoam-100: rgb(206, 247, 243);
    --spectrum-seafoam-200: rgb(170, 241, 234);
    --spectrum-seafoam-300: rgb(140, 233, 226);
    --spectrum-seafoam-400: rgb(101, 218, 210);
    --spectrum-seafoam-500: rgb(63, 201, 193);
    --spectrum-seafoam-600: rgb(15, 181, 174);
    --spectrum-seafoam-700: rgb(0, 161, 154);
    --spectrum-seafoam-800: rgb(0, 140, 135);
    --spectrum-seafoam-900: rgb(0, 119, 114);
    --spectrum-seafoam-1000: rgb(0, 99, 95);
    --spectrum-seafoam-1100: rgb(12, 79, 76);
    --spectrum-seafoam-1200: rgb(18, 60, 58);
    --spectrum-seafoam-1300: rgb(18, 44, 43);
    --spectrum-seafoam-1400: rgb(15, 31, 30);
    --spectrum-cyan-100: rgb(197, 248, 255);
    --spectrum-cyan-200: rgb(164, 240, 255);
    --spectrum-cyan-300: rgb(136, 231, 250);
    --spectrum-cyan-400: rgb(96, 216, 243);
    --spectrum-cyan-500: rgb(51, 197, 232);
    --spectrum-cyan-600: rgb(18, 176, 218);
    --spectrum-cyan-700: rgb(1, 156, 200);
    --spectrum-cyan-800: rgb(0, 134, 180);
    --spectrum-cyan-900: rgb(0, 113, 159);
    --spectrum-cyan-1000: rgb(0, 93, 137);
    --spectrum-cyan-1100: rgb(0, 74, 115);
    --spectrum-cyan-1200: rgb(0, 57, 93);
    --spectrum-cyan-1300: rgb(0, 42, 70);
    --spectrum-cyan-1400: rgb(0, 30, 51);
    --spectrum-blue-100: rgb(224, 242, 255);
    --spectrum-blue-200: rgb(202, 232, 255);
    --spectrum-blue-300: rgb(181, 222, 255);
    --spectrum-blue-400: rgb(150, 206, 253);
    --spectrum-blue-500: rgb(120, 187, 250);
    --spectrum-blue-600: rgb(89, 167, 246);
    --spectrum-blue-700: rgb(56, 146, 243);
    --spectrum-blue-800: rgb(20, 122, 243);
    --spectrum-blue-900: rgb(2, 101, 220);
    --spectrum-blue-1000: rgb(0, 84, 182);
    --spectrum-blue-1100: rgb(0, 68, 145);
    --spectrum-blue-1200: rgb(0, 53, 113);
    --spectrum-blue-1300: rgb(0, 39, 84);
    --spectrum-blue-1400: rgb(0, 28, 60);
    --spectrum-indigo-100: rgb(237, 238, 255);
    --spectrum-indigo-200: rgb(224, 226, 255);
    --spectrum-indigo-300: rgb(211, 213, 255);
    --spectrum-indigo-400: rgb(193, 196, 255);
    --spectrum-indigo-500: rgb(172, 175, 255);
    --spectrum-indigo-600: rgb(149, 153, 255);
    --spectrum-indigo-700: rgb(126, 132, 252);
    --spectrum-indigo-800: rgb(104, 109, 244);
    --spectrum-indigo-900: rgb(82, 88, 228);
    --spectrum-indigo-1000: rgb(64, 70, 202);
    --spectrum-indigo-1100: rgb(50, 54, 168);
    --spectrum-indigo-1200: rgb(38, 41, 134);
    --spectrum-indigo-1300: rgb(27, 30, 100);
    --spectrum-indigo-1400: rgb(20, 22, 72);
    --spectrum-purple-100: rgb(246, 235, 255);
    --spectrum-purple-200: rgb(238, 221, 255);
    --spectrum-purple-300: rgb(230, 208, 255);
    --spectrum-purple-400: rgb(219, 187, 254);
    --spectrum-purple-500: rgb(204, 164, 253);
    --spectrum-purple-600: rgb(189, 139, 252);
    --spectrum-purple-700: rgb(174, 114, 249);
    --spectrum-purple-800: rgb(157, 87, 244);
    --spectrum-purple-900: rgb(137, 61, 231);
    --spectrum-purple-1000: rgb(115, 38, 211);
    --spectrum-purple-1100: rgb(93, 19, 183);
    --spectrum-purple-1200: rgb(71, 12, 148);
    --spectrum-purple-1300: rgb(51, 16, 106);
    --spectrum-purple-1400: rgb(35, 15, 73);
    --spectrum-fuchsia-100: rgb(255, 233, 252);
    --spectrum-fuchsia-200: rgb(255, 218, 250);
    --spectrum-fuchsia-300: rgb(254, 199, 248);
    --spectrum-fuchsia-400: rgb(251, 174, 246);
    --spectrum-fuchsia-500: rgb(245, 146, 243);
    --spectrum-fuchsia-600: rgb(237, 116, 237);
    --spectrum-fuchsia-700: rgb(224, 85, 226);
    --spectrum-fuchsia-800: rgb(205, 58, 206);
    --spectrum-fuchsia-900: rgb(182, 34, 183);
    --spectrum-fuchsia-1000: rgb(157, 3, 158);
    --spectrum-fuchsia-1100: rgb(128, 0, 129);
    --spectrum-fuchsia-1200: rgb(100, 6, 100);
    --spectrum-fuchsia-1300: rgb(71, 14, 70);
    --spectrum-fuchsia-1400: rgb(50, 13, 49);
    --spectrum-magenta-100: rgb(255, 234, 241);
    --spectrum-magenta-200: rgb(255, 220, 232);
    --spectrum-magenta-300: rgb(255, 202, 221);
    --spectrum-magenta-400: rgb(255, 178, 206);
    --spectrum-magenta-500: rgb(255, 149, 189);
    --spectrum-magenta-600: rgb(250, 119, 170);
    --spectrum-magenta-700: rgb(239, 90, 152);
    --spectrum-magenta-800: rgb(222, 61, 130);
    --spectrum-magenta-900: rgb(200, 34, 105);
    --spectrum-magenta-1000: rgb(173, 9, 85);
    --spectrum-magenta-1100: rgb(142, 0, 69);
    --spectrum-magenta-1200: rgb(112, 0, 55);
    --spectrum-magenta-1300: rgb(84, 3, 42);
    --spectrum-magenta-1400: rgb(60, 6, 29);
}
