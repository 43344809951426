:root {
  --spectrum-overlay-opacity: .4;
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-600);
  --spectrum-orange-background-color-default: var(--spectrum-orange-600);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-400);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-500);
  --spectrum-celery-background-color-default: var(--spectrum-celery-600);
  --spectrum-green-background-color-default: var(--spectrum-green-900);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-900);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-900);
  --spectrum-blue-background-color-default: var(--spectrum-blue-900);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-900);
  --spectrum-purple-background-color-default: var(--spectrum-purple-900);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-900);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-900);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-800);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-900);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-600);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-700);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-800);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-700);
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-900);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-1000);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-1100);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-1000);
  --spectrum-informative-background-color-default: var(--spectrum-informative-color-900);
  --spectrum-informative-background-color-hover: var(--spectrum-informative-color-1000);
  --spectrum-informative-background-color-down: var(--spectrum-informative-color-1100);
  --spectrum-informative-background-color-key-focus: var(--spectrum-informative-color-1000);
  --spectrum-negative-background-color-default: var(--spectrum-negative-color-900);
  --spectrum-negative-background-color-hover: var(--spectrum-negative-color-1000);
  --spectrum-negative-background-color-down: var(--spectrum-negative-color-1100);
  --spectrum-negative-background-color-key-focus: var(--spectrum-negative-color-1000);
  --spectrum-positive-background-color-default: var(--spectrum-positive-color-900);
  --spectrum-positive-background-color-hover: var(--spectrum-positive-color-1000);
  --spectrum-positive-background-color-down: var(--spectrum-positive-color-1100);
  --spectrum-positive-background-color-key-focus: var(--spectrum-positive-color-1000);
  --spectrum-neutral-visual-color: var(--spectrum-gray-500);
  --spectrum-accent-visual-color: var(--spectrum-accent-color-800);
  --spectrum-informative-visual-color: var(--spectrum-informative-color-800);
  --spectrum-negative-visual-color: var(--spectrum-negative-color-800);
  --spectrum-notice-visual-color: var(--spectrum-notice-color-600);
  --spectrum-positive-visual-color: var(--spectrum-positive-color-700);
  --spectrum-gray-visual-color: var(--spectrum-gray-500);
  --spectrum-red-visual-color: var(--spectrum-red-800);
  --spectrum-orange-visual-color: var(--spectrum-orange-600);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-600);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-600);
  --spectrum-celery-visual-color: var(--spectrum-celery-700);
  --spectrum-green-visual-color: var(--spectrum-green-700);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-700);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-600);
  --spectrum-blue-visual-color: var(--spectrum-blue-800);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-800);
  --spectrum-purple-visual-color: var(--spectrum-purple-800);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-800);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-800);
  --spectrum-drop-shadow-color: rgba(0, 0, 0, .15);
  --spectrum-background-base-color: var(--spectrum-gray-200);
  --spectrum-background-layer-1-color: var(--spectrum-gray-100);
  --spectrum-background-layer-2-color: var(--spectrum-gray-50);
  --spectrum-gray-50: #fff;
  --spectrum-gray-75: #fdfdfd;
  --spectrum-gray-100: #f8f8f8;
  --spectrum-gray-200: #e6e6e6;
  --spectrum-gray-300: #d5d5d5;
  --spectrum-gray-400: #b1b1b1;
  --spectrum-gray-500: #909090;
  --spectrum-gray-600: #6d6d6d;
  --spectrum-gray-700: #464646;
  --spectrum-gray-800: #222;
  --spectrum-gray-900: #000;
  --spectrum-red-100: #ffebe7;
  --spectrum-red-200: #ffddd6;
  --spectrum-red-300: #ffcdc3;
  --spectrum-red-400: #ffb7a9;
  --spectrum-red-500: #ff9b88;
  --spectrum-red-600: #ff7c65;
  --spectrum-red-700: #f75c46;
  --spectrum-red-800: #ea3829;
  --spectrum-red-900: #d31510;
  --spectrum-red-1000: #b40000;
  --spectrum-red-1100: #930000;
  --spectrum-red-1200: #740000;
  --spectrum-red-1300: #590000;
  --spectrum-red-1400: #430000;
  --spectrum-orange-100: #ffeccc;
  --spectrum-orange-200: #ffdfad;
  --spectrum-orange-300: #fdd291;
  --spectrum-orange-400: #ffbb63;
  --spectrum-orange-500: #ffa037;
  --spectrum-orange-600: #f68511;
  --spectrum-orange-700: #e46f00;
  --spectrum-orange-800: #cb5d00;
  --spectrum-orange-900: #b14c00;
  --spectrum-orange-1000: #953d00;
  --spectrum-orange-1100: #7a2f00;
  --spectrum-orange-1200: #612300;
  --spectrum-orange-1300: #491901;
  --spectrum-orange-1400: #351201;
  --spectrum-yellow-100: #fbf198;
  --spectrum-yellow-200: #f8e750;
  --spectrum-yellow-300: #f8d904;
  --spectrum-yellow-400: #e8c600;
  --spectrum-yellow-500: #d7b300;
  --spectrum-yellow-600: #c49f00;
  --spectrum-yellow-700: #b08c00;
  --spectrum-yellow-800: #9b7800;
  --spectrum-yellow-900: #856600;
  --spectrum-yellow-1000: #705300;
  --spectrum-yellow-1100: #5b4300;
  --spectrum-yellow-1200: #483300;
  --spectrum-yellow-1300: #362500;
  --spectrum-yellow-1400: #281a00;
  --spectrum-chartreuse-100: #dbfc6e;
  --spectrum-chartreuse-200: #cbf443;
  --spectrum-chartreuse-300: #bce92a;
  --spectrum-chartreuse-400: #aad816;
  --spectrum-chartreuse-500: #98c50a;
  --spectrum-chartreuse-600: #87b103;
  --spectrum-chartreuse-700: #769c00;
  --spectrum-chartreuse-800: #678800;
  --spectrum-chartreuse-900: #577400;
  --spectrum-chartreuse-1000: #486000;
  --spectrum-chartreuse-1100: #3a4d00;
  --spectrum-chartreuse-1200: #2c3b00;
  --spectrum-chartreuse-1300: #212c00;
  --spectrum-chartreuse-1400: #181f00;
  --spectrum-celery-100: #cdfcbf;
  --spectrum-celery-200: #aef69d;
  --spectrum-celery-300: #96ee85;
  --spectrum-celery-400: #72e06a;
  --spectrum-celery-500: #4ecf50;
  --spectrum-celery-600: #27bb36;
  --spectrum-celery-700: #07a721;
  --spectrum-celery-800: #009112;
  --spectrum-celery-900: #007c0f;
  --spectrum-celery-1000: #00670f;
  --spectrum-celery-1100: #00530d;
  --spectrum-celery-1200: #00400a;
  --spectrum-celery-1300: #003007;
  --spectrum-celery-1400: #002205;
  --spectrum-green-100: #cef8e0;
  --spectrum-green-200: #adf4ce;
  --spectrum-green-300: #89ecbc;
  --spectrum-green-400: #67dea8;
  --spectrum-green-500: #49cc93;
  --spectrum-green-600: #2fb880;
  --spectrum-green-700: #15a46e;
  --spectrum-green-800: #008f5d;
  --spectrum-green-900: #007a4d;
  --spectrum-green-1000: #00653e;
  --spectrum-green-1100: #005132;
  --spectrum-green-1200: #053f27;
  --spectrum-green-1300: #0a2e1d;
  --spectrum-green-1400: #0a2015;
  --spectrum-seafoam-100: #cef7f3;
  --spectrum-seafoam-200: #aaf1ea;
  --spectrum-seafoam-300: #8ce9e2;
  --spectrum-seafoam-400: #65dad2;
  --spectrum-seafoam-500: #3fc9c1;
  --spectrum-seafoam-600: #0fb5ae;
  --spectrum-seafoam-700: #00a19a;
  --spectrum-seafoam-800: #008c87;
  --spectrum-seafoam-900: #007772;
  --spectrum-seafoam-1000: #00635f;
  --spectrum-seafoam-1100: #0c4f4c;
  --spectrum-seafoam-1200: #123c3a;
  --spectrum-seafoam-1300: #122c2b;
  --spectrum-seafoam-1400: #0f1f1e;
  --spectrum-cyan-100: #c5f8ff;
  --spectrum-cyan-200: #a4f0ff;
  --spectrum-cyan-300: #88e7fa;
  --spectrum-cyan-400: #60d8f3;
  --spectrum-cyan-500: #33c5e8;
  --spectrum-cyan-600: #12b0da;
  --spectrum-cyan-700: #019cc8;
  --spectrum-cyan-800: #0086b4;
  --spectrum-cyan-900: #00719f;
  --spectrum-cyan-1000: #005d89;
  --spectrum-cyan-1100: #004a73;
  --spectrum-cyan-1200: #00395d;
  --spectrum-cyan-1300: #002a46;
  --spectrum-cyan-1400: #001e33;
  --spectrum-blue-100: #e0f2ff;
  --spectrum-blue-200: #cae8ff;
  --spectrum-blue-300: #b5deff;
  --spectrum-blue-400: #96cefd;
  --spectrum-blue-500: #78bbfa;
  --spectrum-blue-600: #59a7f6;
  --spectrum-blue-700: #3892f3;
  --spectrum-blue-800: #147af3;
  --spectrum-blue-900: #0265dc;
  --spectrum-blue-1000: #0054b6;
  --spectrum-blue-1100: #004491;
  --spectrum-blue-1200: #003571;
  --spectrum-blue-1300: #002754;
  --spectrum-blue-1400: #001c3c;
  --spectrum-indigo-100: #edeeff;
  --spectrum-indigo-200: #e0e2ff;
  --spectrum-indigo-300: #d3d5ff;
  --spectrum-indigo-400: #c1c4ff;
  --spectrum-indigo-500: #acafff;
  --spectrum-indigo-600: #9599ff;
  --spectrum-indigo-700: #7e84fc;
  --spectrum-indigo-800: #686df4;
  --spectrum-indigo-900: #5258e4;
  --spectrum-indigo-1000: #4046ca;
  --spectrum-indigo-1100: #3236a8;
  --spectrum-indigo-1200: #262986;
  --spectrum-indigo-1300: #1b1e64;
  --spectrum-indigo-1400: #141648;
  --spectrum-purple-100: #f6ebff;
  --spectrum-purple-200: #edf;
  --spectrum-purple-300: #e6d0ff;
  --spectrum-purple-400: #dbbbfe;
  --spectrum-purple-500: #cca4fd;
  --spectrum-purple-600: #bd8bfc;
  --spectrum-purple-700: #ae72f9;
  --spectrum-purple-800: #9d57f4;
  --spectrum-purple-900: #893de7;
  --spectrum-purple-1000: #7326d3;
  --spectrum-purple-1100: #5d13b7;
  --spectrum-purple-1200: #470c94;
  --spectrum-purple-1300: #33106a;
  --spectrum-purple-1400: #230f49;
  --spectrum-fuchsia-100: #ffe9fc;
  --spectrum-fuchsia-200: #ffdafa;
  --spectrum-fuchsia-300: #fec7f8;
  --spectrum-fuchsia-400: #fbaef6;
  --spectrum-fuchsia-500: #f592f3;
  --spectrum-fuchsia-600: #ed74ed;
  --spectrum-fuchsia-700: #e055e2;
  --spectrum-fuchsia-800: #cd3ace;
  --spectrum-fuchsia-900: #b622b7;
  --spectrum-fuchsia-1000: #9d039e;
  --spectrum-fuchsia-1100: #800081;
  --spectrum-fuchsia-1200: #640664;
  --spectrum-fuchsia-1300: #470e46;
  --spectrum-fuchsia-1400: #320d31;
  --spectrum-magenta-100: #ffeaf1;
  --spectrum-magenta-200: #ffdce8;
  --spectrum-magenta-300: #ffcadd;
  --spectrum-magenta-400: #ffb2ce;
  --spectrum-magenta-500: #ff95bd;
  --spectrum-magenta-600: #fa77aa;
  --spectrum-magenta-700: #ef5a98;
  --spectrum-magenta-800: #de3d82;
  --spectrum-magenta-900: #c82269;
  --spectrum-magenta-1000: #ad0955;
  --spectrum-magenta-1100: #8e0045;
  --spectrum-magenta-1200: #700037;
  --spectrum-magenta-1300: #54032a;
  --spectrum-magenta-1400: #3c061d;
}

:host {
  --spectrum-overlay-opacity: .4;
  --spectrum-gray-background-color-default: var(--spectrum-gray-700);
  --spectrum-red-background-color-default: var(--spectrum-red-600);
  --spectrum-orange-background-color-default: var(--spectrum-orange-600);
  --spectrum-yellow-background-color-default: var(--spectrum-yellow-400);
  --spectrum-chartreuse-background-color-default: var(--spectrum-chartreuse-500);
  --spectrum-celery-background-color-default: var(--spectrum-celery-600);
  --spectrum-green-background-color-default: var(--spectrum-green-900);
  --spectrum-seafoam-background-color-default: var(--spectrum-seafoam-900);
  --spectrum-cyan-background-color-default: var(--spectrum-cyan-900);
  --spectrum-blue-background-color-default: var(--spectrum-blue-900);
  --spectrum-indigo-background-color-default: var(--spectrum-indigo-900);
  --spectrum-purple-background-color-default: var(--spectrum-purple-900);
  --spectrum-fuchsia-background-color-default: var(--spectrum-fuchsia-900);
  --spectrum-magenta-background-color-default: var(--spectrum-magenta-900);
  --spectrum-neutral-background-color-default: var(--spectrum-gray-800);
  --spectrum-neutral-background-color-hover: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-down: var(--spectrum-gray-900);
  --spectrum-neutral-background-color-key-focus: var(--spectrum-gray-900);
  --spectrum-neutral-subdued-background-color-default: var(--spectrum-gray-600);
  --spectrum-neutral-subdued-background-color-hover: var(--spectrum-gray-700);
  --spectrum-neutral-subdued-background-color-down: var(--spectrum-gray-800);
  --spectrum-neutral-subdued-background-color-key-focus: var(--spectrum-gray-700);
  --spectrum-accent-background-color-default: var(--spectrum-accent-color-900);
  --spectrum-accent-background-color-hover: var(--spectrum-accent-color-1000);
  --spectrum-accent-background-color-down: var(--spectrum-accent-color-1100);
  --spectrum-accent-background-color-key-focus: var(--spectrum-accent-color-1000);
  --spectrum-informative-background-color-default: var(--spectrum-informative-color-900);
  --spectrum-informative-background-color-hover: var(--spectrum-informative-color-1000);
  --spectrum-informative-background-color-down: var(--spectrum-informative-color-1100);
  --spectrum-informative-background-color-key-focus: var(--spectrum-informative-color-1000);
  --spectrum-negative-background-color-default: var(--spectrum-negative-color-900);
  --spectrum-negative-background-color-hover: var(--spectrum-negative-color-1000);
  --spectrum-negative-background-color-down: var(--spectrum-negative-color-1100);
  --spectrum-negative-background-color-key-focus: var(--spectrum-negative-color-1000);
  --spectrum-positive-background-color-default: var(--spectrum-positive-color-900);
  --spectrum-positive-background-color-hover: var(--spectrum-positive-color-1000);
  --spectrum-positive-background-color-down: var(--spectrum-positive-color-1100);
  --spectrum-positive-background-color-key-focus: var(--spectrum-positive-color-1000);
  --spectrum-neutral-visual-color: var(--spectrum-gray-500);
  --spectrum-accent-visual-color: var(--spectrum-accent-color-800);
  --spectrum-informative-visual-color: var(--spectrum-informative-color-800);
  --spectrum-negative-visual-color: var(--spectrum-negative-color-800);
  --spectrum-notice-visual-color: var(--spectrum-notice-color-600);
  --spectrum-positive-visual-color: var(--spectrum-positive-color-700);
  --spectrum-gray-visual-color: var(--spectrum-gray-500);
  --spectrum-red-visual-color: var(--spectrum-red-800);
  --spectrum-orange-visual-color: var(--spectrum-orange-600);
  --spectrum-yellow-visual-color: var(--spectrum-yellow-600);
  --spectrum-chartreuse-visual-color: var(--spectrum-chartreuse-600);
  --spectrum-celery-visual-color: var(--spectrum-celery-700);
  --spectrum-green-visual-color: var(--spectrum-green-700);
  --spectrum-seafoam-visual-color: var(--spectrum-seafoam-700);
  --spectrum-cyan-visual-color: var(--spectrum-cyan-600);
  --spectrum-blue-visual-color: var(--spectrum-blue-800);
  --spectrum-indigo-visual-color: var(--spectrum-indigo-800);
  --spectrum-purple-visual-color: var(--spectrum-purple-800);
  --spectrum-fuchsia-visual-color: var(--spectrum-fuchsia-800);
  --spectrum-magenta-visual-color: var(--spectrum-magenta-800);
  --spectrum-drop-shadow-color: rgba(0, 0, 0, .15);
  --spectrum-background-base-color: var(--spectrum-gray-200);
  --spectrum-background-layer-1-color: var(--spectrum-gray-100);
  --spectrum-background-layer-2-color: var(--spectrum-gray-50);
  --spectrum-gray-50: #fff;
  --spectrum-gray-75: #fdfdfd;
  --spectrum-gray-100: #f8f8f8;
  --spectrum-gray-200: #e6e6e6;
  --spectrum-gray-300: #d5d5d5;
  --spectrum-gray-400: #b1b1b1;
  --spectrum-gray-500: #909090;
  --spectrum-gray-600: #6d6d6d;
  --spectrum-gray-700: #464646;
  --spectrum-gray-800: #222;
  --spectrum-gray-900: #000;
  --spectrum-red-100: #ffebe7;
  --spectrum-red-200: #ffddd6;
  --spectrum-red-300: #ffcdc3;
  --spectrum-red-400: #ffb7a9;
  --spectrum-red-500: #ff9b88;
  --spectrum-red-600: #ff7c65;
  --spectrum-red-700: #f75c46;
  --spectrum-red-800: #ea3829;
  --spectrum-red-900: #d31510;
  --spectrum-red-1000: #b40000;
  --spectrum-red-1100: #930000;
  --spectrum-red-1200: #740000;
  --spectrum-red-1300: #590000;
  --spectrum-red-1400: #430000;
  --spectrum-orange-100: #ffeccc;
  --spectrum-orange-200: #ffdfad;
  --spectrum-orange-300: #fdd291;
  --spectrum-orange-400: #ffbb63;
  --spectrum-orange-500: #ffa037;
  --spectrum-orange-600: #f68511;
  --spectrum-orange-700: #e46f00;
  --spectrum-orange-800: #cb5d00;
  --spectrum-orange-900: #b14c00;
  --spectrum-orange-1000: #953d00;
  --spectrum-orange-1100: #7a2f00;
  --spectrum-orange-1200: #612300;
  --spectrum-orange-1300: #491901;
  --spectrum-orange-1400: #351201;
  --spectrum-yellow-100: #fbf198;
  --spectrum-yellow-200: #f8e750;
  --spectrum-yellow-300: #f8d904;
  --spectrum-yellow-400: #e8c600;
  --spectrum-yellow-500: #d7b300;
  --spectrum-yellow-600: #c49f00;
  --spectrum-yellow-700: #b08c00;
  --spectrum-yellow-800: #9b7800;
  --spectrum-yellow-900: #856600;
  --spectrum-yellow-1000: #705300;
  --spectrum-yellow-1100: #5b4300;
  --spectrum-yellow-1200: #483300;
  --spectrum-yellow-1300: #362500;
  --spectrum-yellow-1400: #281a00;
  --spectrum-chartreuse-100: #dbfc6e;
  --spectrum-chartreuse-200: #cbf443;
  --spectrum-chartreuse-300: #bce92a;
  --spectrum-chartreuse-400: #aad816;
  --spectrum-chartreuse-500: #98c50a;
  --spectrum-chartreuse-600: #87b103;
  --spectrum-chartreuse-700: #769c00;
  --spectrum-chartreuse-800: #678800;
  --spectrum-chartreuse-900: #577400;
  --spectrum-chartreuse-1000: #486000;
  --spectrum-chartreuse-1100: #3a4d00;
  --spectrum-chartreuse-1200: #2c3b00;
  --spectrum-chartreuse-1300: #212c00;
  --spectrum-chartreuse-1400: #181f00;
  --spectrum-celery-100: #cdfcbf;
  --spectrum-celery-200: #aef69d;
  --spectrum-celery-300: #96ee85;
  --spectrum-celery-400: #72e06a;
  --spectrum-celery-500: #4ecf50;
  --spectrum-celery-600: #27bb36;
  --spectrum-celery-700: #07a721;
  --spectrum-celery-800: #009112;
  --spectrum-celery-900: #007c0f;
  --spectrum-celery-1000: #00670f;
  --spectrum-celery-1100: #00530d;
  --spectrum-celery-1200: #00400a;
  --spectrum-celery-1300: #003007;
  --spectrum-celery-1400: #002205;
  --spectrum-green-100: #cef8e0;
  --spectrum-green-200: #adf4ce;
  --spectrum-green-300: #89ecbc;
  --spectrum-green-400: #67dea8;
  --spectrum-green-500: #49cc93;
  --spectrum-green-600: #2fb880;
  --spectrum-green-700: #15a46e;
  --spectrum-green-800: #008f5d;
  --spectrum-green-900: #007a4d;
  --spectrum-green-1000: #00653e;
  --spectrum-green-1100: #005132;
  --spectrum-green-1200: #053f27;
  --spectrum-green-1300: #0a2e1d;
  --spectrum-green-1400: #0a2015;
  --spectrum-seafoam-100: #cef7f3;
  --spectrum-seafoam-200: #aaf1ea;
  --spectrum-seafoam-300: #8ce9e2;
  --spectrum-seafoam-400: #65dad2;
  --spectrum-seafoam-500: #3fc9c1;
  --spectrum-seafoam-600: #0fb5ae;
  --spectrum-seafoam-700: #00a19a;
  --spectrum-seafoam-800: #008c87;
  --spectrum-seafoam-900: #007772;
  --spectrum-seafoam-1000: #00635f;
  --spectrum-seafoam-1100: #0c4f4c;
  --spectrum-seafoam-1200: #123c3a;
  --spectrum-seafoam-1300: #122c2b;
  --spectrum-seafoam-1400: #0f1f1e;
  --spectrum-cyan-100: #c5f8ff;
  --spectrum-cyan-200: #a4f0ff;
  --spectrum-cyan-300: #88e7fa;
  --spectrum-cyan-400: #60d8f3;
  --spectrum-cyan-500: #33c5e8;
  --spectrum-cyan-600: #12b0da;
  --spectrum-cyan-700: #019cc8;
  --spectrum-cyan-800: #0086b4;
  --spectrum-cyan-900: #00719f;
  --spectrum-cyan-1000: #005d89;
  --spectrum-cyan-1100: #004a73;
  --spectrum-cyan-1200: #00395d;
  --spectrum-cyan-1300: #002a46;
  --spectrum-cyan-1400: #001e33;
  --spectrum-blue-100: #e0f2ff;
  --spectrum-blue-200: #cae8ff;
  --spectrum-blue-300: #b5deff;
  --spectrum-blue-400: #96cefd;
  --spectrum-blue-500: #78bbfa;
  --spectrum-blue-600: #59a7f6;
  --spectrum-blue-700: #3892f3;
  --spectrum-blue-800: #147af3;
  --spectrum-blue-900: #0265dc;
  --spectrum-blue-1000: #0054b6;
  --spectrum-blue-1100: #004491;
  --spectrum-blue-1200: #003571;
  --spectrum-blue-1300: #002754;
  --spectrum-blue-1400: #001c3c;
  --spectrum-indigo-100: #edeeff;
  --spectrum-indigo-200: #e0e2ff;
  --spectrum-indigo-300: #d3d5ff;
  --spectrum-indigo-400: #c1c4ff;
  --spectrum-indigo-500: #acafff;
  --spectrum-indigo-600: #9599ff;
  --spectrum-indigo-700: #7e84fc;
  --spectrum-indigo-800: #686df4;
  --spectrum-indigo-900: #5258e4;
  --spectrum-indigo-1000: #4046ca;
  --spectrum-indigo-1100: #3236a8;
  --spectrum-indigo-1200: #262986;
  --spectrum-indigo-1300: #1b1e64;
  --spectrum-indigo-1400: #141648;
  --spectrum-purple-100: #f6ebff;
  --spectrum-purple-200: #edf;
  --spectrum-purple-300: #e6d0ff;
  --spectrum-purple-400: #dbbbfe;
  --spectrum-purple-500: #cca4fd;
  --spectrum-purple-600: #bd8bfc;
  --spectrum-purple-700: #ae72f9;
  --spectrum-purple-800: #9d57f4;
  --spectrum-purple-900: #893de7;
  --spectrum-purple-1000: #7326d3;
  --spectrum-purple-1100: #5d13b7;
  --spectrum-purple-1200: #470c94;
  --spectrum-purple-1300: #33106a;
  --spectrum-purple-1400: #230f49;
  --spectrum-fuchsia-100: #ffe9fc;
  --spectrum-fuchsia-200: #ffdafa;
  --spectrum-fuchsia-300: #fec7f8;
  --spectrum-fuchsia-400: #fbaef6;
  --spectrum-fuchsia-500: #f592f3;
  --spectrum-fuchsia-600: #ed74ed;
  --spectrum-fuchsia-700: #e055e2;
  --spectrum-fuchsia-800: #cd3ace;
  --spectrum-fuchsia-900: #b622b7;
  --spectrum-fuchsia-1000: #9d039e;
  --spectrum-fuchsia-1100: #800081;
  --spectrum-fuchsia-1200: #640664;
  --spectrum-fuchsia-1300: #470e46;
  --spectrum-fuchsia-1400: #320d31;
  --spectrum-magenta-100: #ffeaf1;
  --spectrum-magenta-200: #ffdce8;
  --spectrum-magenta-300: #ffcadd;
  --spectrum-magenta-400: #ffb2ce;
  --spectrum-magenta-500: #ff95bd;
  --spectrum-magenta-600: #fa77aa;
  --spectrum-magenta-700: #ef5a98;
  --spectrum-magenta-800: #de3d82;
  --spectrum-magenta-900: #c82269;
  --spectrum-magenta-1000: #ad0955;
  --spectrum-magenta-1100: #8e0045;
  --spectrum-magenta-1200: #700037;
  --spectrum-magenta-1300: #54032a;
  --spectrum-magenta-1400: #3c061d;
}



/*# sourceMappingURL=light-theme.4c51ef8a.css.map */
